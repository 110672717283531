/* eslint-disable array-callback-return */
import {
  faHeart,
  faTags,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { ProductInterface } from "../../interfaces/ServerInterfaces";
import imageDefault from "../../assets/jpg/no-imagen-shop.jpg"
import { getColorShowRemainQuantities, getShowRemainQuantities } from "../../utils/functions";
import { RootState } from "../../store/store";
import { useAppSelector } from "../../store/hooks";
import { productsWithFlavorsId } from "../../utils/staticData";

interface Props {
  product: ProductInterface;
  onClick: Function;
  priceSystemId: number;
  cardWidth: string;
  addCart: Function;
  includeShop: boolean;
  when_shop_create_preorder: boolean;
  enable_to_sale_in_negative: boolean;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const CardProduct = ({
  product,
  onClick,
  priceSystemId,
  cardWidth,
  addCart,
  includeShop,
  when_shop_create_preorder,
  enable_to_sale_in_negative
}: Props) => {


  const { currentCurrency } = useAppSelector((state: RootState) => state.session)

  const getButtonText = () => {
    if (includeShop) {
      if (productsWithFlavorsId.some(id => id === product.id) && product.totalQuantity > 0) {
        return "Elija su sabor"
      } else {
        if (product.type === "ADDON" || product.type === "MENU" || product.type === "SERVICE" || when_shop_create_preorder || enable_to_sale_in_negative) {
          return "Añadir a la bolsa";
        }
        else if ((product.stockLimit && product.totalQuantity <= 0) || product.totalQuantity <= 0) {
          return "Añadir a la bolsa"
          // return "Agotado"
        }
        else if (product.stockLimit && product.totalQuantity > 0) {
          return product.type === "VARIATION" ? "Mostrar opciones" : "Añadir a la bolsa";
        } else {
          return product.type === "VARIATION" ? "Mostrar opciones" : "Añadir a la bolsa";
        }
      }
    } else {
      return "Ver más";
    }
  };

  const handleButtonClick = () => {
    if (productsWithFlavorsId.some(id => product.id === id)) {
      onClick(product)
    } else {
      if (product.type === "ADDON" || product.type === "MENU" || product.type === "SERVICE" || when_shop_create_preorder || enable_to_sale_in_negative) {
        addCart({
          ...product,
          prices: [
            {
              price: product.prices.find(elem => elem.codeCurrency === currentCurrency)?.price,
              codeCurrency: product.prices.find(elem => elem.codeCurrency === currentCurrency)?.codeCurrency
            }
          ]
        })

      } else {
        // if (product.totalQuantity > 0 || (product.stockLimit && product.totalQuantity > 0)) {
        if (product.type === "VARIATION") {
          onClick(product)
        }
        else {
          addCart({
            ...product,
            prices: [
              {
                price: product.prices.find(elem => elem.codeCurrency === currentCurrency)?.price,
                codeCurrency: product.prices.find(elem => elem.codeCurrency === currentCurrency)?.codeCurrency
              }
            ]
          })
        }
        // }
      }
    }
  };

  return (
    <>
      <div
        className={`h-96 ${cardWidth}   rounded-lg scrollbar-hide  overflow-hidden   shadow-md inline-block  cursor-pointer  ease-in-out duration-300`}
      >
        <div className="h-52 w-full min-w-full relative flex items-center justify-center">
          <LazyLoadImage
            src={
              product?.images.length !== undefined && product?.images.length > 0
                ? product?.images[0].src
                : imageDefault
            }
            alt={product?.name}
            className="h-52 w-full object-cover hover:scale-105 transition-transform"
            onClick={() => onClick(product)}
            effect="blur"
          />
        </div>

        <div className="flex flex-1 w-full whitespace-nowrap  flex-col space-y-1 p-2">
          <h3
            className="text-lg truncate font-medium text-slate-900"
            onClick={() => onClick(product)}
          >
            {product.name}
          </h3>
          <p className="text-sm truncate h-5 -mt-2 text-slate-500">
            {" "}
            {product.description}
            <span className="text-red-500">
              {product?.name === "Cono Vacío" && "Solo en tienda fisica"}
            </span>
          </p>

          {product.showRemainQuantities && (
            <p
              className={classNames(
                `${getColorShowRemainQuantities(product.totalQuantity)}`,
                "text-xs font-medium truncate h-5 -mt-4"
              )}
            >
              {" "}
              {!(when_shop_create_preorder || enable_to_sale_in_negative) &&
                getShowRemainQuantities(product.totalQuantity)}
            </p>
          )}
          <div className="flex flex-row flex-nowrap ">
            {product.onSale && (
              <h5 className={`text-base font-medium mr-2 text-slate-900 `}>
                {product.onSalePrice?.amount +
                  " " +
                  product.onSalePrice?.codeCurrency}
              </h5>
            )}
            <h5
              className={`text-base  font-medium  text-slate-900 ${
                product.onSale && "line-through text-slate-500"
              }`}
            >
              {product.prices.length === 1
                ? // ?  product.prices[0].price + " " + product.prices[0].codeCurrency
                  Intl.NumberFormat("en-IN").format(product.prices[0].price) +
                  (Number.isInteger(product.prices[0].price) ? ".00 " : " ") +
                  product.prices[0].codeCurrency
                : product.prices.map((item) => {
                    if (item.codeCurrency === currentCurrency) {
                      return item.price + " " + item.codeCurrency;
                    }
                  })}
            </h5>
          </div>
          {!includeShop ? (
            <button
              type="button"
              onClick={() => {
                onClick(product);
              }}
              className="w-full overflow-hidden truncate   inline-flex  items-center   justify-center text-center rounded-md border border-transparent bg-cyan-100  py-2 text-sm font-medium text-cyan-700 hover:bg-cyan-200 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
            >
              {product.onSale && (
                <FontAwesomeIcon
                  icon={faTags}
                  className=" mx-1"
                  size="sm"
                  aria-hidden="true"
                />
              )}
              {product?.suggested && (
                <FontAwesomeIcon
                  icon={faHeart}
                  className=" mx-1"
                  size="sm"
                  aria-hidden="true"
                />
              )}
              Ver más
            </button>
          ) : (
            <>
              <button
                type="button"
                className={
                  product.type === "ADDON" ||
                  product.type === "MENU" ||
                  product.type === "SERVICE" ||
                  when_shop_create_preorder ||
                  enable_to_sale_in_negative
                    ? "w-full overflow-hidden truncate   inline-flex  items-center   justify-center text-center rounded-md border border-transparent bg-pink-500  py-2 text-sm font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
                    : (product.stockLimit && product?.totalQuantity === 0) ||
                      product?.totalQuantity === 0
                    ? "w-full overflow-hidden truncate inline-flex  items-center   justify-center text-center rounded-md border border-transparent bg-red-600  py-2 text-sm font-medium text-white  focus:outline-none focus:ring-2 focus:ring-red-900 focus:ring-offset-2"
                    : product.stockLimit && product?.totalQuantity > 0
                    ? "w-full overflow-hidden truncate   inline-flex  items-center   justify-center text-center rounded-md border border-transparent bg-pink-500  py-2 text-sm font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
                    : product.stockLimit
                    ? "w-full overflow-hidden truncate inline-flex  items-center   justify-center text-center rounded-md border border-transparent bg-red-600  py-2 text-sm font-medium text-white  focus:outline-none focus:ring-2 focus:ring-red-900 focus:ring-offset-2"
                    : "w-full overflow-hidden truncate   inline-flex  items-center   justify-center text-center rounded-md border border-transparent bg-pink-500  py-2 text-sm font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
                }
                onClick={handleButtonClick}
              >
                {product.onSale && (
                  <FontAwesomeIcon
                    icon={faTags}
                    className=" mx-1"
                    size="sm"
                    aria-hidden="true"
                  />
                )}
                {product?.suggested && (
                  <FontAwesomeIcon
                    icon={faHeart}
                    className=" mx-1"
                    size="sm"
                    aria-hidden="true"
                  />
                )}
                {getButtonText()}
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(CardProduct);
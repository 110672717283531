/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { type  LoaderFunctionArgs, Route, redirect, RouterProvider, createBrowserRouter, createRoutesFromElements, Outlet } from 'react-router-dom'
import { BusinessShopPage } from '../pages/BusinessShopPage'
import { useServerOrders } from '../services/useServerOrders'
import { useAppSelector } from '../store/hooks'
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PaymentFailed from '../pages/PaymentFailed'
import PaymentSuccess from '../pages/PaymentSuccess'
import PaymentCompleted from '../pages/PaymentCompleted'
import OrdersHistory from '../pages/OrdersHistory'

// DOCS: Old code with error
// export const MainRoute = () => {
//
//   const { getMyOrders } = useServerOrders()
//
//   const { key } = useAppSelector(state => state.session)
//
//   useEffect(() => {
//     if (key !== null) {
//       getMyOrders({ per_page: 1 })
//     }
//   }, []);
//
//   return (
//     <BrowserRouter>
//       <ToastContainer
//         position="top-center"
//         autoClose={3000}
//         hideProgressBar
//         newestOnTop={false}
//         closeOnClick
//         rtl={false}
//         pauseOnFocusLoss={false}
//         draggable
//         pauseOnHover={true}
//         theme="light"
//         transition={Flip}
//       />
//       <Routes>
//         <Route path='/' element={<BusinessShopPage />} />
//         <Route path='/paymentfailed' element={<PaymentFailed />} />
//         <Route path='/paymentsuccess' element={<PaymentSuccess />} />
//
//         <Route path='/payment_completed' element={<PaymentCompleted />} />
//         <Route path='/orders_historial' element={<OrdersHistory />} />
//
//
//       </Routes>
//     </BrowserRouter>
//   )
// }

export const rmSearchQueryLoader = ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url)
  const search = url.searchParams

  if(!!Array.from(search.keys()).length) return redirect(url.pathname)

  return null
}

export const InitPages = () => {
  const { getMyOrders } = useServerOrders()

  const { key } = useAppSelector(state => state.session)

  useEffect(() => {
    if (key !== null) {
      getMyOrders({ per_page: 1 })
    }
  }, []);

  return <><Outlet /></>
}

export const MainRoute = () => {
  return <RouterProvider 
    router={createBrowserRouter(createRoutesFromElements(
      <Route path='/' element={<>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={true}
          theme="light"
          transition={Flip}
        />
        <InitPages />
      </>
      }
      loader={rmSearchQueryLoader}
      >
        <Route path='/' element={<BusinessShopPage />} />
        <Route path='/paymentfailed' element={<PaymentFailed />} />
        <Route path='/paymentsuccess' element={<PaymentSuccess />} />
                                                                           
        <Route path='/payment_completed' element={<PaymentCompleted />} />
        <Route path='/orders_historial' element={<OrdersHistory />} />
      </Route>
  ))}
  />
}


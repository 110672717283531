import { AvailableAddonInterface } from "../interfaces/ServerInterfaces";

export const orderStatus: {
  code: string;
  value: string;
  color: string;
}[] = [
  {
    code: "WITH_ERRORS",
    value: "Con errores",
    color: "red",
  },
  {
    code: "CREATED",
    value: "Creada",
    color: "blue",
  },
  {
    code: "PAYMENT_PENDING",
    value: "Pendiente de pago",
    color: "yellow",
  },
  {
    code: "IN_PROCESS",
    value: "Procesando",
    color: "blue",
  },
  {
    code: "BILLED",
    value: "Facturada",
    color: "green",
  },
  {
    code: "CANCELLED",
    value: "Cancelada",
    color: "red",
  },
  {
    code: "REFUNDED",
    value: "Reembolsada",
    color: "yellow",
  },
  {
    code: "COMPLETED",
    value: "Completada",
    color: "green",
  },
  {
    code: "IN_TRANSIT",
    value: "En tránsito",
    color: "blue",
  },
  {
    code: "DELIVERED",
    value: "Entregada",
    color: "green",
  },
];

export const productsWithFlavorsId: number[] = [
  //Helados
  31274, 19174, 19173, 19175, 19130, 19118, 19117, 19116, 19115, 19114, 19113,
  19103, 19186, 19102, 19109, 40370, 40371,
  //Yogurts
  40396,
  // 23715, 23721, 23732, 23730, 23734, 23718, 23727, 23727, 30185, 30184, 30182,
  // 30181, 30180, 30179, 30178, 30177, 30176,
];

export const productsWithFreeAddons: number[] = [
  //Hamburguesas, papas fritas, bolitas de queso y croquetas
  30186, 30187, 30188, 28857, 28858, 37958, 19158, 19104, 37959, 19157, 19160,
  //Helados
  31274, 19174, 19173, 19175, 19130, 19118, 19117, 19116, 19115, 19114, 19113,
  19103, 19186, 19102, 19109, 40370, 40371,
];

export const productsForCakeCustomization: number[] = [
  19100, 19726, 28912, 18985, 18992, 19041, 18990, 23904, 23903, 19003, 18994,
  19032, 19088,
];

export const MeetsWithCoccionTerms: number[] = [
  // Carnes
  19120, 19128, 40404,
];

export const ProductWithSauce: number[] = [
  // Carnes
  19120, 19128, 19161,
];
export const EggsWithCoccionTerms: number[] = [
  // Huevos
  19204, 19137, 40449, 19138, 19139, 19141, 19140,
];

// Para domicilio
export const IceCreamForDelivery: number[] = [40371, 40370, 19186, 19102];

// Para llevar consumiendo
export const IceCreamsToTakeAwayConsuming: number[] = [
  19113, 19114, 19173, 19174, 19175, 19103,
];

// Ofertas de Helado para consumo exclusivo en el local
export const IceCreamForOnPremiseConsumption: number[] = [
  19115, 19116, 19130, 19117, 19118, 19103,
];

export const MeetsCoccionTerms: AvailableAddonInterface[] = [
  {
    id: 1,
    name: "Rojo",
  },
  {
    id: 2,
    name: "Rojo medio",
  },
  {
    id: 3,
    name: "Término medio",
  },
  {
    id: 4,
    name: "Tres cuartos",
  },
  {
    id: 5,
    name: "Muy cocido",
  },
];

export const EggsCoccionTerms: AvailableAddonInterface[] = [
  {
    id: 1,
    name: "Blando",
  },
  {
    id: 2,
    name: "Blando / Volteado",
  },
  {
    id: 3,
    name: "Duro",
  },
];

export const IceCreamflavors = [
  { name: "Sorbete-limón", color: "#32CD32" },
  { name: "Coco", color: "#FFA500" },
  { name: "Mamey", color: "#8A2BE2" },
  { name: "Mango", color: "#FF4500" },
  { name: "Guanábana", color: "#FFC0CB" },
  { name: "Guayaba", color: "#ADD8E6" },
  { name: "Piña-royal", color: "#FFD700" },
  { name: "Frutos del-Bosque", color: "#FF6347" },
  { name: "Fresa", color: "#FFFF00" },
  { name: "Selva-negra", color: "#00FFFF" },
  { name: "Banana-Glazed", color: "#32CD32" },
  { name: "Hawai", color: "#FFA07A" },
  { name: "Mantecado", color: "#A52A2A" },
  { name: "Crema inglesa", color: "#DC143C" },
  { name: "Stella", color: "#8B4513" },
  { name: "Cheesecake", color: "#00FA9A" },
  { name: "Cookies", color: "#FFA500" },
  { name: "Chicle", color: "#D2B48C" },
  { name: "Macarena", color: "#FF69B4" },
  { name: "Stracciatella", color: "#FFFF00" },
  { name: "Riquísimo", color: "#8B4513" },
  { name: "Òreo", color: "#FFF8DC" },
  { name: "Pistacho", color: "#32CD32" },
  { name: "Amarena", color: "#FF6347" },
  { name: "Cassata", color: "#8B4513" },
  { name: "Yogurt", color: "#D2691E" },
  { name: "Chocolate", color: "#DAA520" },
  { name: "Choco-Almendra", color: "#4169E1" },
  { name: "Chocolate-Dark", color: "#FFA500" },
];

export const Yogurtflavors = [
  { name: "caramelo", color: "#D2691E" },
  { name: "fresa", color: "#FF69B4" },
  { name: "uva", color: "#800080" },
  { name: "mango", color: "#FFA500" },
  { name: "piña", color: "#FFD700" },

  { name: "amarena", color: "#FFD700" },
  { name: "frutos del bosque", color: "#FFD700" },
  { name: "aràndanos", color: "#FFD700" },
  { name: "guanàbana", color: "#FFD700" },
  { name: "guayaba", color: "#FFD700" },
  { name: "stracciatella", color: "#FFD700" },
];

export const FreeAddons: AvailableAddonInterface[] = [
  {
    id: 1,
    name: "Ketchup",
  },
  {
    id: 2,
    name: "Mayonesa",
  },
  {
    id: 3,
    name: "Mostaza",
  },
];

export const FreeIceCreamAddons: AvailableAddonInterface[] = [
  {
    id: 1,
    name: "Galletica",
  },
  {
    id: 3,
    name: "Sirope",
  },
];
export const FreeIceCreamAddonsPlus: AvailableAddonInterface[] = [
  {
    id: 1,
    name: "Galletica",
  },
  {
    id: 2,
    name: "Nata montada",
  },
  {
    id: 3,
    name: "Sirope",
  },
];

export const BreakfastAddOnData: AvailableAddonInterface[] = [
  {
    id: 1,
    name: "Jugo",
  },
  {
    id: 2,
    name: "Café expreso",
  },
  {
    id: 3,
    name: "Café americano",
  },
  {
    id: 4,
    name: "Café cortadito",
  },
];
